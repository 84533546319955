import React, { useEffect, useRef, useState } from 'react'
import PageHeading from '../../components/PageHeading';
import CardBasic from '../../components/Cards/Basic';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from "../../redux/actions";
import { fetchReportStokis } from '../../redux/actions/stokis';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { formatCurrency, formatDate2 } from '../../constants/utility';
import DatePicker from '../../components/DatePicker';
import PrintingHeader from '../../components/PrintingHeader';
import FilterPopover from '../../components/FilterPopover';
import Select from 'react-select';
import ModalAction from '../../components/ModalAction';

const StokisReport = (props) => {
  const dispatch = useDispatch();
  const printRef = useRef();

  const { name, title, parent } = props.data;
  const [rowDetail, setRowDetail] = useState(null);
  const { report } = useSelector((state) => state.stokis);
  const [search, setSearch] = useState('');

  let date = new Date();
  const [filterCount, setFilterCount] = useState(0);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);

  const currentMonth = new Date().getMonth() + 1; // Get the current month (1-12)
  const currentYear = new Date().getFullYear();
  const [query, setQuery] = useState({
    month: currentMonth,
    year: currentYear
  })
  const [modalOption, setModalOption] = useState({})
  const [modalShow, setModalShow] = useState(false);

  const handleModal = (data) => {
    setModalShow(true);
    setModalOption({
      title: "Tracking Order",
      content: (
        <>
          <div className="pr-3">
            <div className="px-2 d-flex mt-1" style={{ fontSize: '0.9rem' }}>
              <div className="mr-2" style={{ width: '180px' }}>
                No. Invoice
              </div>
              <div className="mr-2">:</div>
              <div className="font-weight-bold">
                {data?.no_invoice}
              </div>
            </div>
            <div className="px-2 d-flex mt-1" style={{ fontSize: '0.9rem' }}>
              <div className="mr-2" style={{ width: '180px' }}>
                Tgl. Invoice
              </div>
              <div className="mr-2">:</div>
              <div className="font-weight-bold">
                {formatDate2(data?.tgl_invoice, true, false, false)}
              </div>
            </div>
            <div className="px-2 d-flex mt-1" style={{ fontSize: '0.9rem' }}>
              <div className="mr-2" style={{ width: '180px' }}>
                Total Harga
              </div>
              <div className="mr-2">:</div>
              <div className="font-weight-bold">
                {formatCurrency(data?.total_harga)}
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: "10px", width: "100%" }}>
              <table style={{ borderCollapse: 'collapse', border: '1px solid black', width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid black', padding: '8px' }}>Nama</th>
                    <th style={{ border: '1px solid black', padding: '8px' }}>Deskripsi</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data?.order_details.map((value, index) => (
                      <tr key={`${index}`}>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{value?.barang.nama}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{value?.barang.deskripsi}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div className="px-3 py-3 border-top">
            <div className="flex">
              <button className="btn mr-2 btn-outline-secondary btn-sm" onClick={() => setModalShow(false)}>
                Tutup
              </button>
            </div>
          </div>
        </>

      ),
      color: "info",
      size: "lg",
      data: data,
    });
    // props.setActionChild(false);
  };

  useEffect(() => {
    dispatch(
      actions.setMenuActive({ menuActive: parent || name, subMenuActive: name })
    );

    const params = {
      page_size: sizePerPage,
      page: page,
      month: query.month,
      year: query.year
    };

    dispatch(fetchReportStokis(params));
  }, [dispatch, parent, name, query, page, sizePerPage]);

  const columns = [
    {
      dataField: 'no_invoice',
      text: 'No. Invoice',
      sort: true,
      headerClasses: (column, colIndex) => {
        return 'py-2 px-2';
      },
      classes: (column, colIndex) => {
        return 'p-2';
      },
      formatter: (val) => {
        return val ? val : 'Tidak ada invoice'
      },
    },
    {
      dataField: 'total_harga',
      text: 'Total Harga',
      sort: true,
      headerClasses: (column, colIndex) => {
        return 'py-2 px-2';
      },
      classes: (column, colIndex) => {
        return 'p-2';
      },
      formatter: (val) => {
        return formatCurrency(val)
      },
    },
    {
      dataField: 'tgl_invoice',
      text: 'Tgl. Invoice',
      sort: true,
      headerClasses: (column, colIndex) => {
        return 'py-2 px-2';
      },
      classes: (column, colIndex) => {
        return 'p-2';
      },
      formatter: (val) => {
        return formatDate2(val, true, false, false)
      },
    }
  ];

  const filters = [
    {
      name: 'month',
      label: 'Bulan',
      type: 'select',
      options: [
        { value: 1, label: 'Januari' },
        { value: 2, label: 'Febuari' },
        { value: 3, label: 'Maret' },
        { value: 4, label: 'April' },
        { value: 5, label: 'Mei' },
        { value: 6, label: 'Juni' },
        { value: 7, label: 'Juli' },
        { value: 8, label: 'Agustus' },
        { value: 9, label: 'September' },
        { value: 10, label: 'Oktober' },
        { value: 11, label: 'November' },
        { value: 12, label: 'Desember' }
      ],
    },
    {
      name: 'year',
      label: 'Tahun',
      type: 'select',
      options: Array.from({ length: currentYear - 2023 + 1 }, (_, index) => ({
        value: 2023 + index,
        label: (2023 + index).toString(),
      })),
    },
  ];

  const filterChanges = (data) => {
    const count = []
    count.push({ month: data.month }, { year: data.year })
    setQuery({
      month: data.month,
      year: data.year
    })
    setFilterCount(count.length)
  };

  const handleTableChange = (
    type,
    { page, sizePerPage }
  ) => {
    if (type === 'pagination') {
      setPage(page);
      setSizePerPage(sizePerPage);
    }
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setModalShow(true)
      handleModal(row)
    },
  };

  return (
    <div>
      <PageHeading title={title} />
      <div className="row animate-col-size-change">
        <div className={rowDetail ? `col-md-7` : `col-md-12`}>
          <CardBasic nopadding>
            <div className="py-3 px-3 d-sm-block d-md-flex justify-content-between">
              <div className="d-flex">
                <div className="m-1">
                  <FilterPopover
                    filters={filters}
                    filterChanges={(data) => filterChanges(data)}
                    filterCount={filterCount}
                  />
                </div>
              </div>
            </div>
            <div
              className="container-print media-print-p2"
              ref={printRef}>
              <PrintingHeader
                title={'Report Stokis'}
                subtitle={`${report?.rows?.id_member} - ${report?.rows?.nama || ""}`}
                date={[report?.rows?.periode.start, report?.rows?.periode.end]}
                printBy={null}
                printAt={null}
              />
              {
                report?.rows?.order.length > 0 && (
                  <>
                  
                    <div
                      className="px-2 d-flex mt-5"
                      style={{ fontSize: '0.9rem' }}>
                      <div className="mr-2" style={{ width: '180px' }}>
                        Total Order
                      </div>
                      <div className="mr-2">:</div>
                      <div className="font-weight-bold">
                        {formatCurrency(report?.rows?.total_order)}
                      </div>
                    </div>
                    <div
                      className="px-2 d-flex mt-1"
                      style={{ fontSize: '0.9rem' }}>
                      <div className="mr-2" style={{ width: '180px' }}>
                        Jenis Stokis
                      </div>
                      <div className="mr-2">:</div>
                      <div className="font-weight-bold">
                        {report?.rows?.jenis_paket}
                      </div>
                    </div>
                    <div
                      className="px-2 d-flex mt-1"
                      style={{ fontSize: '0.9rem' }}>
                      <div className="mr-2" style={{ width: '180px' }}>
                        Kualifikasi
                      </div>
                      <div className="mr-2">:</div>
                      <div className="font-weight-bold">
                        {report?.rows?.qualified}
                      </div>
                    </div>
                    <div
                      className="px-2 d-flex mt-1"
                      style={{ fontSize: '0.9rem' }}>
                      <div className="mr-2" style={{ width: '180px' }}>
                        Estimasi Bonus
                      </div>
                      <div className="mr-2">:</div>
                      <div className="font-weight-bold">
                        {formatCurrency(report?.rows?.estimated_bonus)}
                      </div>
                    </div>
                    <div
                      className="px-2 d-flex mt-1"
                      style={{ fontSize: '0.9rem' }}>
                      <div className="mr-2" style={{ width: '180px' }}>
                        Total BV
                      </div>
                      <div className="mr-2">:</div>
                      <div className="font-weight-bold">
                        {report?.rows?.total_bv}
                      </div>
                    </div>
                    <div
                      className="px-2 d-flex mt-1"
                      style={{ fontSize: '0.9rem' }}>
                      <div className="mr-2" style={{ width: '180px' }}>
                        Bv yang dibutuhkan
                      </div>
                      <div className="mr-2">:</div>
                      <div className="font-weight-bold">
                        {report?.rows?.required_met}
                      </div>
                    </div>
                    <div
                      className="px-2 d-flex mt-1"
                      style={{ fontSize: '0.9rem' }}>
                      <div className="mr-2" style={{ width: '180px' }}>
                        Presentasi Bonus
                      </div>
                      <div className="mr-2">:</div>
                      <div className="font-weight-bold">
                        {report?.rows?.profit_share_presentage} %
                      </div>
                    </div>
                  </>
                )
              }
            </div>
            <div style={{ marginTop: "20px" }}>
              <RemotePagination
                data={report ? report?.rows?.order : []}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalSize}
                columns={columns}
                onRowClick={rowEvents}
                onTableChange={handleTableChange}
              />
            </div>
          </CardBasic>
        </div>
        <ModalAction
          show={modalShow}
          modalOption={modalOption}
          onHide={() => {
            setModalShow(false);
            // setFormOpen(true);
          }}
        />
      </div>
    </div>
  )
}

const RemotePagination = ({
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  columns,
  onRowClick,
}) => {
  const indication = () => {
    return 'No data';
  };
  return (
    <div className="table-fixied custom-scrollbar table-responsive my-table">
      <BootstrapTable
        remote
        className="bt-table"
        bootstrap4
        hover
        condensed
        noDataIndication={indication}
        bordered={false}
        keyField="id"
        data={data}
        columns={columns}
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        rowEvents={onRowClick}
        onTableChange={onTableChange}
      />
    </div>
  );
};

export default StokisReport