export const CLICK_OPEN_MENU = 'CLICK_OPEN_MENU';

// Auth
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const AUTH_USER_DATA = 'AUTH_USER_DATA';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const USERNAME = 'USERNAME';
export const USERNAME_CHECK = 'USERNAME_CHECK';

// error modal
export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';
export const SHOW_PROGRESSBAR = 'SHOW_PROGRESSBAR';

// menu
export const SET_EXPAND_SIDEBAR = 'SET_EXPAND_SIDEBAR';
export const SET_MENU_ACTIVE = 'SET_MENU_ACTIVE';

// ------------- Action Report ------------ //

export const GET_REPORT_PERKEMBANGAN_JARINGAN =
  'GET_REPORT_PERKEMBANGAN_JARINGAN';
export const GET_REPORT_BONUS = 'GET_REPORT_BONUS';
export const GET_REPORT_BONUS_BULANAN = 'GET_REPORT_BONUS_BULANAN';
export const GET_REPORT_BONUS_HARIAN = 'GET_REPORT_BONUS_HARIAN';
export const GET_REDEMPTION_STATEMENT = 'GET_REDEMPTION_STATEMENT';
export const GET_DOWNLINE_REPORT = 'GET_DOWNLINE_REPORT';
export const GET_REPORT_OMSET = 'GET_REPORT_OMSET';
export const GET_REPORT_PPH = 'GET_REPORT_PPH';

export const GET_REPORT_DASHBOARD = 'GET_REPORT_DASHBOARD';
export const GET_BANNER_PROMO = 'GET_BANNER_PROMO';
export const REPORT_FORM_RESULT = 'REPORT_FORM_RESULT';
export const GET_REPORT_UPGRADE = 'GET_REPORT_UPGRADE';
export const GET_REPORT_REGISTER = 'GET_REPORT_REGISTER';
export const GET_MEDIA = 'GET_MEDIA';
export const GET_BANNER = 'GET_BANNER';

// ------------------------------------------------- //

// ------------- Action Profile ------------ //

export const GET_ACCOUNT_PROFILE = 'GET_ACCOUNT_PROFILE';
export const GET_ACCOUNT_ADDRESS = 'GET_ACCOUNT_ADDRESS';
export const ACCOUNT_FORM_RESULT = 'ACCOUNT_FORM_RESULT';
export const CHECK_MEMBER_BONUS_STATUS = 'CHECK_MEMBER_BONUS_STATUS';

// ------------------------------------------------- //

// ------------- Action Transaction ------------ //

export const GET_BARANG = 'GET_BARANG';
export const GET_TRANSACTION_ORDER = 'GET_TRANSACTION_ORDER';
export const GET_TRANSACTION_HISTORY = 'GET_TRANSACTION_HISTORY';
export const GET_TRANSACTION_KODE = 'GET_TRANSACTION_KODE';
export const GET_TRANSACTION_INVOICE = 'GET_TRANSACTION_INVOICE';
export const GET_TRANSACTION_DOWNLINE = 'GET_TRANSACTION_DOWNLINE';
export const TRANSACTION_FORM_RESULT = 'TRANSACTION_FORM_RESULT';
export const TRANSACTION_PAYMENT_TOKEN = 'TRANSACTION_PAYMENT_TOKEN';
export const TRANSACTION_PAYMENT_ADDRESS = 'TRANSACTION_PAYMENT_ADDRESS';
export const SET_PROCESS_SELESAI = 'SET_PROCESS_SELESAI';
export const GET_RETUR = 'GET_RETUR';
export const GET_RETUR_ABLE = 'GET_RETUR_ABLE';
export const FORM_ORDER = 'FORM_ORDER';
export const GET_SHIPPING = 'GET_SHIPPING';
export const GET_GUDANG = 'GET_GUDANG';

export const PENGIRIMAN_COST = 'PENGIRIMAN_COST';
// ------------------------------------------------- //
export const GET_TRACKING = 'GET_TRACKING';
// ------------- Action Infomasi ------------ //

export const GET_INFORMASI_LIST = 'GET_INFORMASI_LIST';
export const INFORMASI_FORM_RESULT = 'INFORMASI_FORM_RESULT';

// ------------------------------------------------- //

export const GET_LIST_BANK = 'GET_LIST_BANK';
export const GET_BONUS_UNPAYOUT = 'GET_BONUS_UNPAYOUT';
export const SET_REPORT_PAYOUT = 'SET_REPORT_PAYOUT';
export const GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD';
export const GET_SETTINGS = 'GET_SETTINGS';

export const GET_PROMO_OPT = 'GET_PROMO_OPT';
export const GET_PROMO_DETAIL = 'GET_PROMO_DETAIL';


// ------------------------------------------------- //
export const GET_REPORT_STOKIS = 'GET_REPORT_STOKIS';
