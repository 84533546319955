import React from 'react';
import Profile from './pages/Account/Profile';
import RincianBonus from './pages/Bonus/RincianBonus/index';
import Withdraw from './pages/Bonus/Withdraw';
import Dashboard from './pages/Dashboard';
import Informasi from './pages/News/Informasi';
import PerkembanganJaringan from './pages/Report/PerkembanganJaringan';
import BonusBulanan from './pages/Report/BonusBulanan';
import RedemptionStatement from './pages/Report/RedemptionStatement';
// import RegisterMember from "./pages/Report/PerkembanganJaringan/Register";
import RiwayatTransaksi from './pages/Transaksi/RiwayatTransaksi/index';
import CreateTransaksi from './pages/Transaksi/RiwayatTransaksi/CreateTransaksi';
import TransaksiRetur from './pages/Transaksi/TransaksiRetur';
import TransaksiDetail from './pages/Transaksi/RiwayatTransaksi/TransaksiDetail';
import BonusHarian from './pages/Report/BonusHarian';
import DownlineReport from './pages/Report/Downline';
import Omset from './pages/Report/Omset';
import Promo from './pages/Report/Promo';
import PPhReport from './pages/Report/PPh';
import CekOngkir from './pages/Transaksi/Cek-Ongkir';
import StokisReport from './pages/Stokis';

export const menus = [
  {
    name: 'dashboard',
    label: 'Dashboard',
    title: 'Dashboard',
    path: '/',
    icon: 'fa-tachometer-alt',
    component: (data) => <Dashboard data={data} />,
  },
  {
    name: 'transaksi',
    label: 'Transaksi',
    path: '#',
    icon: 'fa-exchange-alt',
    subs: [
      {
        name: 'order-transaksi-create',
        label: 'Buat Transaksi',
        path: '/transaksi/order-transaksi/:jenis',
        title: 'Buat Transaksi',
        parent: 'transaksi',
        hidden: true,
        component: (data) => <CreateTransaksi data={data} />,
      },
      {
        name: 'order-transaksi-detail',
        label: 'Detail Transaksi',
        path: '/transaksi/order-transaksi/detail/:id',
        title: 'Detail Transaksi',
        parent: 'transaksi',
        hidden: true,
        component: (data) => <TransaksiDetail data={data} />,
      },
      {
        name: 'order-transaksi',
        label: 'Transaksi Order',
        path: '/transaksi/order-transaksi',
        title: 'Transaksi Order',
        parent: 'transaksi',
        component: (data) => <RiwayatTransaksi data={data} />,
      },
      {
        name: 'transaksi-retur',
        label: 'Transaksi Retur',
        path: '/transaksi/retur',
        title: 'Transaksi Retur',
        parent: 'transaksi',
        component: (data) => <TransaksiRetur data={data} />,
      },
      {
        name: 'cek-ongkir',
        label: 'Cek Ongkir',
        path: '/transaksi/cek-ongkir',
        title: 'Cek Ongkir Pengiriman',
        parent: 'transaksi',
        component: (data) => <CekOngkir data={data} />,
      },
    ],
  },
  {
    name: 'report',
    label: 'Report',
    path: '#',
    icon: 'fa-chart-bar',
    subs: [
      {
        name: 'report-tree',
        label: 'Perkembangan Jaringan',
        path: '/report/tree',
        title: 'Perkembangan Jaringan',
        parent: 'report',
        component: (data) => <PerkembanganJaringan data={data} />,
      },
      {
        name: 'report-promo',
        label: 'Promo',
        path: '/report/promo',
        title: 'Promo',
        parent: 'report',
        component: (data) => <Promo data={data} />,
      },
      {
        name: 'report-bonus-harian',
        label: 'Bonus Harian',
        path: '/report/bonus-harian',
        title: 'Bonus Harian',
        parent: 'report',
        component: (data) => <BonusHarian data={data} />,
      },
      {
        name: 'report-bonus-bulanan',
        label: 'Bonus Bulanan',
        path: '/report/bonus-bulanan',
        title: 'Bonus Bulanan',
        parent: 'report',
        component: (data) => <BonusBulanan data={data} />,
      },
      {
        name: 'report-penarikan-bonus',
        label: 'Penarikan Bonus',
        path: '/report/penarikan-bonus',
        title: 'Penarikan Bonus',
        parent: 'report',
        component: (data) => <RedemptionStatement data={data} />,
      },
      {
        name: 'report-downline',
        label: 'Report Downline',
        path: '/report/downline-report',
        title: 'Report Downline',
        parent: 'report',
        component: (data) => <DownlineReport data={data} />,
      },
      {
        name: 'report-omset',
        label: 'Report Omset',
        path: '/report/omset',
        title: 'Report Omset',
        parent: 'report',
        component: (data) => <Omset data={data} />,
      },
      {
        name: 'report-pph',
        label: 'PPh',
        path: '/report/pph',
        title: 'PPh',
        parent: 'report',
        component: (data) => <PPhReport data={data} />,
      },
      // {
      //   name: "report-tree",
      //   label: "Perkembangan Jaringan",
      //   path: "/report/tree/register/:id_upline/:sisi",
      //   title: "Register Member",
      //   parent: "report",
      //   hidden: true,
      //   component: (data) => <RegisterMember data={data} />,
      // },
    ],
  },
  {
    name: 'bonus',
    label: 'Bonus',
    path: '#',
    icon: 'fa-money-bill-wave-alt',
    devider: false,
    subs: [
      {
        name: 'rincian-bonus',
        label: 'Rincian Bonus',
        path: '/bonus/rincian-bonus',
        title: 'Rincian Bonus',
        parent: 'bonus',
        component: (data) => <RincianBonus data={data} />,
      },
      {
        name: 'withdraw',
        label: 'Withdraw',
        path: '/bonus/withdraw',
        title: 'Withdraw',
        parent: 'bonus',
        component: (data) => <Withdraw data={data} />,
      },
    ],
  },
  {
    name: 'stokis',
    label: 'Stokis',
    path: '#',
    icon: 'fa fa-suitcase',
    devider: true,
    option: "hide",
    subs: [
      {
        name: 'stokis-report',
        label: 'Stokis Report',
        path: '/stokis/stokis-report',
        title: 'Stokis',
        parent: 'stokis',
        component: (data) => <StokisReport data={data} />,
      }
    ],
  },
  {
    name: 'informasi',
    label: 'Informasi',
    path: '/news/informasi',
    icon: 'fa-newspaper',
    heading: 'News & Tips',
    devider: true,
    title: 'Informasi',
    component: (data) => <Informasi data={data} />,
  },
  {
    name: 'profile',
    hidden: true,
    label: 'Profile',
    path: '/account/profile',
    title: 'Profile Member',
    parent: 'account',
    component: (data) => <Profile data={data} />,
  },
];
