import { updateObject } from "../../../constants/utility";
import * as actionTypes from '../../../constants/actionTypes';

const initialState = {
  report: null
};


const getReportStokis = (state, data) => {
  return updateObject(state, {
    report: data,
  });
};

const reducer = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case actionTypes.GET_REPORT_STOKIS:
      return getReportStokis(state, data);
    default:
      return state;
  }
};

export default reducer;
