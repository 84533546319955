import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";
import { persistAuth } from "./redux/actions/login";

//Pages
import SignIn from "./pages/SignIn";
import NotFound from "./pages/NotFound";
import { menus } from "./settings";
import ChangePasswordJs from "./pages/Account/ChangePassword.js";
import Alamat from "./pages/Account/Alamat";
import Progressbar from "./components/Progressbar";
import VerifikasiBank from "./pages/Account/VerifikasiBank";
import VerifikasiNpwp from "./pages/Account/VerifikasiNpwp";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import { AuthWrapper } from "./pages/AuthWrapper";
import { persistFormOrder } from "./redux/actions/transaction";

const Router = () => {
  const dispatch = useDispatch();
  const {
    persisting,
    loggedIn,
    data: user,
  } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.alert);
  useEffect(() => {
    dispatch(persistAuth());
    dispatch(persistFormOrder());
  }, [dispatch]);

  if (persisting) {
    return null;
  }

  let routes = [];
  const processMenuItems = (menuItems) => {
    menuItems.forEach((item) => {
      if (item.component) routes.push(item);
      if (item.subs) {
        item.subs.forEach((sub) => {
          if (sub.component) routes.push(sub);
        });
      }
    });
  };

  if (user && user.stokis === true) {
    processMenuItems(menus);
  } else {
    processMenuItems(menus.filter((value) => value.name !== 'stokis'));
  }

  routes.push({
    name: "404notfound",
    label: "404 Notfound",
    path: "*",
    title: "404 Notfound",
    component: (data) => <NotFound data={data} />,
  });

  routes.push({
    name: "changepassword",
    label: "Change Password",
    path: "/account/change-password",
    title: "Ubah Password",
    parent: "account",
    component: (data) => <ChangePasswordJs data={data} />,
  });

  routes.push({
    name: "alamat",
    label: "Alamat",
    path: "/account/address",
    title: "Alamat",
    parent: "account",
    component: (data) => <Alamat data={data} />,
  });

  return (
    <BrowserRouter>
      <Progressbar loading={isLoading} />
      {!loggedIn ? (
        <Routes>
          <Route exact path="/signin" element={<SignIn />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route
            exact
            path="/verifikasi-bank/:token"
            element={<VerifikasiBank />}
          />
          <Route
            exact
            path="/verifikasi-npwp/:token"
            element={<VerifikasiNpwp />}
          />
          <Route path="*" element={<Navigate replace to="/signin" />} />
        </Routes>
      ) : (
        <AuthWrapper>
          <Routes>
            <Route
              exact
              path="/verifikasi-bank/:token"
              element={<VerifikasiBank />}
            />
            <Route
              exact
              path="/verifikasi-npwp/:token"
              element={<VerifikasiNpwp />}
            />
            {routes.map((menu) => {
              return (
                menu.component && (
                  <Route
                    exact
                    key={`route-${menu.name}`}
                    path={menu.path}
                    element={menu.component(menu)}
                  />
                )
              );
            })}
            <Route exact path="/signin" element={<Navigate replace to="/" />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthWrapper>
      )}
    </BrowserRouter>
  );
};

export default Router;
