import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CardBasic from '../../../components/Cards/Basic';
import DatePicker from '../../../components/DatePicker';
import FilterPopover from '../../../components/FilterPopover';
//Navigation
import PageHeading from '../../../components/PageHeading';
import Searchbar from '../../../components/Searchbar';
import { formatCurrency, formatDate2 } from '../../../constants/utility';
import * as actions from '../../../redux/actions';
import {
  fetchTransactionHistory,
  setFormOrder,
  setPaymentToken,
} from '../../../redux/actions/transaction';
import TransaksiDetail from './TransaksiDetail';

const RiwayatTransaksi = (props) => {
  const dispatch = useDispatch();
  const { name, title, parent } = props.data;

  let date = new Date();
  const [filterDate, setFilterDate] = useState({
    start: new Date(date.setDate(date.getDate() - 30)),
    end: new Date(),
  });

  const [search, setSearch] = useState('');
  const [filterred, setFilterred] = useState({});
  const [filterCount, setFilterCount] = useState(0);
  const [sort, setSort] = useState(null);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);

  const [rowDetail, setRowDetail] = useState(null);
  const {
    history: data,
    formResult,
    track,
    formOrder,
  } = useSelector((state) => state.transaction);

  const { data: userData } = useSelector((state) => state.auth);

  const [activeId, setActiveId] = useState(null);
  const [actionChild, setActionChild] = useState(false);

  useEffect(() => {
    if (data) {
      setTotalSize(data.total_count);
      if (data) {
        data.rows
          .filter((item) => item.public_id === activeId, [])
          .map((item) => setRowDetail(item));
      }
    }
  }, [data, activeId]);

  useEffect(() => {
    if (!rowDetail) setActiveId(null);
    else setActiveId(rowDetail.public_id);
  }, [rowDetail]);

  const columns = [
    {
      dataField: 'tgl_order',
      text: 'Tanggal',
      sort: true,
      headerClasses: (column, colIndex) => {
        return 'py-2 px-2';
      },
      classes: (column, colIndex) => {
        return 'p-2';
      },
      formatter: (val) => {
        return formatDate2(val, false, false, true);
      },
    },
    {
      dataField: 'no_order',
      text: 'No Order',
      sort: true,
      headerClasses: (column, colIndex) => {
        return 'py-2 px-2';
      },
      classes: (column, colIndex) => {
        return 'p-2';
      },
    },
    {
      dataField: 'no_invoice',
      text: 'No Inv',
      sort: true,
      headerClasses: (column, colIndex) => {
        return 'py-2 px-2';
      },
      classes: (column, colIndex) => {
        return 'p-2';
      },
      formatter: (val) => val || '-',
    },
    {
      dataField: 'total_harga',
      text: 'Total',
      sort: true,
      headerClasses: (column, colIndex) => {
        return 'py-2 px-2';
      },
      classes: (column, colIndex) => {
        return 'p-2 text-nowrap text-right';
      },
      formatter: (val) => {
        return formatCurrency(val);
      },
    },
    {
      dataField: 'status_order',
      text: 'Status',
      sort: true,
      headerClasses: (column, colIndex) => {
        return 'py-2 px-2 text-center';
      },
      classes: (column, colIndex) => {
        return 'p-2';
      },
      formatter: (val, row) => {
        return (
          <div className="text-center">
            {val === 'PENDING' && (
              <span className="badge badge-pill badge-warning font-weight-bold">
                <strong> {val}</strong>
              </span>
            )}
            {val === 'PAID' && (
              <span className="badge badge-pill badge-info">
                <strong>{val}</strong>
              </span>
            )}
            {val === 'PICKUPABLE' && (
              <span className="badge badge-pill badge-info">
                <strong>{val}</strong>
              </span>
            )}
            {val === 'DELIVERY' && (
              <span className="badge badge-pill badge-info">
                <strong>{val}</strong>
              </span>
            )}
            {val === 'RECEIVED' && (
              <span className="badge badge-pill badge-info">
                <strong>{val}</strong>
              </span>
            )}
            {val === 'DONE' && (
              <span className="badge badge-pill badge-success">
                <strong>{val}</strong>
              </span>
            )}
            {val === 'CANCEL' && (
              <span className="badge badge-pill badge-danger">
                <strong>{val}</strong>
              </span>
            )}
            {val === 'EXPIRED' && (
              <span className="badge badge-pill badge-danger">
                <strong>{val}</strong>
              </span>
            )}
            {val === 'RETUR' && (
              <span className="badge badge-pill badge-danger">
                <strong>{val}</strong>
              </span>
            )}
          </div>
        );
      },
    },
  ];
  
  useEffect(() => {
    dispatch(
      actions.setMenuActive({ menuActive: parent || name, subMenuActive: name })
    );
    dispatch(setPaymentToken(null));
  }, [dispatch, parent, name]);

  useEffect(() => {
    if (!filterDate.start || !filterDate.end) {
      return;
    }

    const params = {
      search: search,
      page_size: sizePerPage,
      page: page,
      filter: {
        tgl_order: {
          op: 'between',
          value: {
            from: filterDate.start,
            to: filterDate.end,
          },
        },
      },
      sort: sort,
    };

    if (filterred) {
      for (var x in filterred) {
        params.filter[x] = filterred[x];
      }
    }

    dispatch(fetchTransactionHistory(params));
  }, [
    dispatch,
    filterred,
    search,
    filterDate,
    sort,
    page,
    sizePerPage,
    formResult,
    track,
  ]);

  const filterChanges = (data) => {
    let count = 0;
    let filter = {};
    for (var x in data) {
      let item = data[x];
      if (item?.length > 0) {
        let value = item.map((i) => i.value);

        filter[x] = {
          op: 'in',
          value: value,
        };
        count += value?.length;
      }
    }
    setFilterred(filter);
    setFilterCount(count);
  };

  const filters = [
    {
      name: 'status_order',
      label: 'Status Transaksi',
      type: 'multiselect',
      options: [
        { value: 'PENDING', label: 'PENDING' },
        { value: 'PAID', label: 'PAID' },
        { value: 'PICKUPABLE', label: 'PICKUPABLE' },

        // { value: 'GUDANG', label: 'GUDANG' },
        { value: 'DELIVERY', label: 'DELIVERY' },
        { value: 'RECEIVED', label: 'RECEIVED' },
        { value: 'DONE', label: 'DONE' },
        { value: 'CANCEL', label: 'CANCEL' },
        { value: 'EXPIRED', label: 'EXPIRED' },
      ],
    },
  ];

  if (data?.opt_member?.length > 0) {
    let options = data.opt_member.map((v) => {
      return { value: v.id_member, label: v.id_member + '-' + v.nama };
    });
    filters.push({
      name: 'member',
      label: 'Member',
      type: 'multiselect',
      options: options,
    });
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      navigate('/transaksi/order-transaksi/detail/' + row.public_id, {
        replace: false,
      });
    },
  };

  const handleTableChange = (
    type,
    { sortField, sortOrder, data, page, sizePerPage }
  ) => {
    console.log('oceoce', type, page, sizePerPage);
    if (type === 'sort') {
      setSort({
        orderby: sortField,
        order: sortOrder,
      });
    }

    if (type === 'pagination') {
      setPage(page);
      setSizePerPage(sizePerPage);
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    console.log('xna', 'fired', navigate);
  }, [dispatch]);
  const createOrder = (jenis) => {
    if (formOrder?.jenis !== jenis) {
      const formData = {
        jenis: jenis,
        minBv: 0,
        activeIndex: 0,
        activeStep: 'item',
        steps: [
          {
            name: 'item',
            label: 'Item/Paket',
            icon: 'fas fa-shopping-bag',
            data: null,
            passed: false,
          },
          {
            name: 'delivery',
            label: 'Pengiriman',
            icon: 'fas fa-shipping-fast',
            data: null,
            passed: false,
          },
          {
            name: 'payment',
            label: 'Pembayaran',
            icon: 'fas fa-money-check',
            data: null,
            passed: false,
          },
        ],
      };
      if (jenis === 'registrasi') {
        formData.activeStep = 'member';
        formData.steps = [
          {
            name: 'member',
            data: null,
            label: 'Member',
            icon: 'fas fa-user',
            passed: false,
          },
          ...formData.steps,
        ];
      }
      dispatch(setFormOrder(formData));
    }
    navigate(`${jenis}`);
  };
  return (
    <div>
      <PageHeading title={title} />
      {/* {!isCreate ? ( */}
      <>
        {' '}
        <div className="row animate-col-size-change">
          <div className={rowDetail ? `col-md-7` : `col-md-12`}>
            <CardBasic nopadding>
              <div className="d-block d-md-flex justify-content-between p-2">
                <div className="d-flex">
                  <div className="m-1">
                    <FilterPopover
                      filters={filters}
                      filterChanges={(data) => filterChanges(data)}
                      filterCount={filterCount}
                    />
                  </div>
                  <div className="m-1">
                    <DatePicker
                      value={filterDate.start}
                      endDate={filterDate.end}
                      onChange={setFilterDate}
                      range
                    />
                  </div>

                  <div className="m-1 no-mb">
                    <Searchbar
                      placeholder="Cari No Transaksi"
                      onEnter={(data) => setSearch(data)}
                    />
                  </div>
                </div>

                <div className="d-block d-md-flex">
                  {/* <Link to={`/transaksi/order-transaksi/registrasi`}> */}
                  <button
                    onClick={() => createOrder('registrasi')}
                    className="btn btn-primary-f text-nowrap btn-md"
                    style={{ margin: 2, height: '40px' }}>
                    Registrasi
                  </button>
                  {/* </Link> */}
                  {/* <Link to={`/transaksi/order-transaksi/upgrade`}> */}
                  <button
                    disabled={userData?.posisi === 'PLATINUM'}
                    onClick={() => createOrder('upgrade')}
                    className="btn btn-primary-f text-nowrap"
                    style={{ margin: 2, height: '40px' }}>
                    Upgrade
                  </button>
                  {/* </Link> */}
                  {/* <Link to={`/transaksi/order-transaksi/repeat-order`}> */}
                  {/* <button
                    onClick={() => createOrder('repeat-order')}
                    className="btn  btn-primary-f  text-nowrap"
                    style={{ margin: 2, height: '40px' }}>
                    Repeat Order
                  </button> */}
                  {/* </Link> */}
                </div>
              </div>

              <RemotePagination
                data={data ? data.rows : []}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalSize}
                columns={columns}
                onRowClick={rowEvents}
                onTableChange={handleTableChange}
              />
            </CardBasic>
          </div>
          {rowDetail && (
            <TransaksiDetail
              data={rowDetail}
              setRowDetail={setRowDetail}
              actionChild={actionChild}
              setActionChild={setActionChild}
            />
          )}
        </div>{' '}
      </>
    </div>
  );
};

const RemotePagination = ({
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  columns,
  onRowClick,
}) => {
  const indication = () => {
    return 'No data';
  };
  return (
    <div className="table-fixied custom-scrollbar table-responsive my-table">
      <BootstrapTable
        remote
        className="bt-table"
        bootstrap4
        hover
        condensed
        noDataIndication={indication}
        bordered={false}
        keyField="no_order"
        data={data}
        columns={columns}
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        rowEvents={onRowClick}
        onTableChange={onTableChange}
      />
    </div>
  );
};

export default RiwayatTransaksi;
