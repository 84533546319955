import * as actionTypes from "../../../constants/actionTypes";
import * as api from "../api";

const getDataReportStokis = (data) => {
  return {
    type: actionTypes.GET_REPORT_STOKIS,
    data: data,
  };
};

export const fetchReportStokis = (query) => async (dispatch) => {
  try {
    const { result } = await api.getReportStokisApi(query);
    dispatch(getDataReportStokis(result));
  } catch (err) {
    console.error(err);
  }
};


