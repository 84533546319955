import { combineReducers } from "redux";
import accountReducer from "./account";
import alertReducer from "./alert";
import authReducer from "./auth";
import informasiReducer from "./informasi";
import menuReducer from "./menu";
import optReducer from "./opt";
// external imports
import { MenuOpenReducer } from "./MenuOpenReducer";
import reportReducer from "./report";
import transactionReducer from "./transaction";
import stokisReducer from "./stokis";

const reducers = combineReducers({
  auth: authReducer,
  menuState: MenuOpenReducer,
  alert: alertReducer,
  menu: menuReducer,
  informasi: informasiReducer,
  account: accountReducer,
  report: reportReducer,
  transaction: transactionReducer,
  opt: optReducer,
  stokis: stokisReducer
});

export default reducers;
