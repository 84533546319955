import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../redux/actions";
import {
  fetchAccountAddress,
  fetchAccountProfile,
} from "../../../redux/actions/account";

const Topbar = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.auth);
  const { profile, address } = useSelector((state) => state.account);
  const { public_id } = useSelector((state) => state.auth.data);
  const { expandSidebar } = useSelector(state => state.menu);

  useEffect(() => {
    // dispatch(fetchAccountProfile(public_id));
    dispatch(fetchAccountAddress(public_id));
  }, [dispatch, public_id]);

  const clickMenuOpen = () => {
    dispatch(actions.setExpandSidebar());
  };

  useEffect(() => {
    // console.log('xna',window.screen.width)
    if (window.screen.width > 768) clickMenuOpen();
  }, [])

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar static-top shadow">
      {/* <!-- Sidebar Toggle (Topbar) --> */}
      {!expandSidebar && (
        <button
          onClick={() => {
            clickMenuOpen();
          }}
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
        >
          <i className="fa fa-bars"></i>
        </button>
      )}

      {/* "navbar-nav ml-auto-md sm-ml-n4" */}
      {/* <!-- Topbar Navbar --> */}
      <ul className="navbar-nav ml-n4 ml-md-1">
        {
          data?.stokis && data?.stokis === true && (
            <li className="nav-item dropdown no-arrow">
              <div
                className="nav-link dropdown-toggle"
                id="userDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="d-flex align-items-center">
                  <span className="badge badge-info p-2" style={{ fontSize: "13px" }}>Member Stokis</span>
                </div>
              </div>
            </li>
          )
        }
      </ul>



      <ul className="navbar-nav ml-auto">
        {/* <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
        {/* <!-- Nav Item - Alerts --> */}
        <li className="nav-item dropdown no-arrow mx-1">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="alertsDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fas fa-bell fa-fw"></i>
            {/* <!-- Counter - Alerts --> */}
            <span className="badge badge-danger badge-counter">*</span>
          </a>
          {/* <!-- Dropdown - Alerts --> */}
          <div
            className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="alertsDropdown"
          >
            <h6 className="dropdown-header">Alerts</h6>
            {!profile?.no_rekening && (
              <Link to={"/account/profile"}>
                <div
                  className="dropdown-item d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <div className="mr-3">
                    <div className="icon-circle bg-primary">
                      <i className="fas fa-money-bill-wave text-white"></i>
                    </div>
                  </div>
                  <div>Jangan lupa segera lengkapi data rekening anda 🥰.</div>
                </div>
              </Link>
            )}

            {!profile?.npwp && (
              <Link to={"/account/profile"}>
                <div
                  className="dropdown-item d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <div className="mr-3">
                    <div className="icon-circle bg-primary">
                      <i className="fas fa-newspaper text-white"></i>
                    </div>
                  </div>
                  <div>Jangan lupa segera lengkapi data npwp anda 😍.</div>
                </div>
              </Link>
            )}

            {!address?.rows?.length && (
              <Link to={"/account/address"}>
                <div
                  className="dropdown-item d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <div className="mr-3">
                    <div className="icon-circle bg-primary">
                      <i className="fas fa-map-marker-alt text-white"></i>
                    </div>
                  </div>
                  <div>
                    Lengkapi alamat agar mudah dalam melakukan transaksi 🚚.
                  </div>
                </div>
              </Link>
            )}

            {/* <a
              className="dropdown-item text-center small text-gray-500"
              href="#"
            >
              Show All Alerts
            </a> */}
          </div>
        </li>


        <div className="topbar-divider d-none d-sm-block"></div>

        {/* <!-- Nav Item - User Information --> */}
        <li className="nav-item dropdown no-arrow">
          <div
            className="nav-link dropdown-toggle"
            id="userDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div className="d-flex align-items-center">
              <span className="mr-2 d-none mt-1 d-lg-inline text-gray-600 small" style={{ fontWeight: "bold" }}>
                {profile?.username}
              </span>
              <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" style={{ fontSize: "20px" }}></i>
            </div>
            {/* <img
              alt="profile"
              className="img-profile rounded-circle"
              src="https://source.unsplash.com/QAB-WJcbgJk/60x60"
            /> */}
          </div>
          {/* <!-- Dropdown - User Information --> */}
          <div
            className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="userDropdown"
          >
            <Link className="dropdown-item" to={"/account/profile"}>
              <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
              Profile
            </Link>
            <Link className="dropdown-item" to={"/account/change-password"}>
              <i className="fas fa-key fa-sm fa-fw mr-2 text-gray-400"></i>
              Ubah Password
            </Link>
            <Link className="dropdown-item" to={"/account/address"}>
              <i className="fas fa-map-marked-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              Alamat
            </Link>
            <div className="dropdown-divider"></div>
            <div
              className="dropdown-item"
              data-toggle="modal"
              data-target="#logoutModal"
              onClick={() => dispatch(actions.logout())}
            >
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              Logout
            </div>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Topbar;
